import * as React from 'react';
import './Main.css';
import { bindActionCreators } from 'redux';
import { VideoFrame } from './VideoFrame';
import { connect } from 'react-redux';
import { IDispatch, RootState } from '../store/rootState';
import { UiActions } from '../store/ui/actions';
import FlashOnIcon from '@material-ui/icons/FlashOn';
import FlashOffIcon from '@material-ui/icons/FlashOff';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import InvertColorsIcon from '@material-ui/icons/InvertColors';
import InvertColorsOffIcon from '@material-ui/icons/InvertColorsOff';
import CameraIcon from '@material-ui/icons/Camera';
import SettingsIcon from '@material-ui/icons/Settings';
import TuneIcon from '@mui/icons-material/Tune';
import Ad from './Ad';

interface StateProps {
  isFlashOn: boolean;
  isFreezeOn: boolean;
  isInvertOn: boolean;
  isSliderOn: boolean;
}

export interface DispatchProps {
  uiActions: typeof UiActions;
}

type Props = StateProps & DispatchProps;

class MainImpl extends React.Component<Props> {
  videoRef: React.RefObject<HTMLVideoElement> | undefined;

  setVideoElement(element: React.RefObject<HTMLVideoElement>) {
    this.videoRef = element;
  }

  captureAndDownloadFrame() {
    if (!this.videoRef || !this.videoRef.current) return;
    const video = this.videoRef.current;
    // Create a canvas element
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
  
    // Get the canvas context
    const context = canvas.getContext('2d');
  
    if (!context) return;

    // Draw the current frame of the video onto the canvas
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
  
    // Create a temporary link element
    const link = document.createElement('a');
    link.href = canvas.toDataURL('image/jpeg');
    link.download = 'magnifier.jpeg';
  
    // Simulate a click event to trigger the download
    link.dispatchEvent(
      new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window,
      })
    );
  }  

  render() {
    return (
      <div className="container">
        <div className="videoHeader">
          { <Ad dataAdSlot='2840353899' /> }
        </div>
        <VideoFrame setVideoElement={(element: React.RefObject<HTMLVideoElement>) => this.setVideoElement(element)} />
        <div className="videoFooter">
          <div className="buttons">
            <button
              type="button"
              className="actionButton"
              onClick={() => this.props.uiActions.toggleFlash(!this.props.isFlashOn)}>
              {this.props.isFlashOn ? <FlashOffIcon /> : <FlashOnIcon />}
            </button>
            <button
              type="button"
              className="actionButton"
              onClick={() => this.props.uiActions.toggleFreeze(!this.props.isFreezeOn)}>
              {this.props.isFreezeOn ? <PlayArrowIcon /> : <AcUnitIcon />}
            </button>
            {<button
              type="button"
              className="actionButton"
              onClick={() => this.captureAndDownloadFrame()}>
              <CameraIcon />
            </button>}
            <button
              type="button"
              className="actionButton"
              onClick={() => this.props.uiActions.toggleInvert(!this.props.isInvertOn)}>
              {this.props.isInvertOn ? <InvertColorsOffIcon /> : <InvertColorsIcon />}
            </button>
            <button
              type="button"
              className="actionButton"
              onClick={() => this.props.uiActions.toggleSlider(!this.props.isSliderOn)}>
              <TuneIcon />
            </button>
            <button
              type="button"
              className="actionButton"
              onClick={() => { window.location.href = '/settings'; }}>
              <SettingsIcon />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export const Main: any = connect<StateProps, DispatchProps, never, never>(
  (state: RootState) => ({
    isFlashOn: state.ui.isFlashOn,
    isFreezeOn: state.ui.isFreezeOn,
    isInvertOn: state.ui.isInvertOn,
    isSliderOn: state.ui.isSliderOn,
  }),
  (dispatch: IDispatch) => ({
    uiActions: bindActionCreators(UiActions, dispatch)
  })
)(MainImpl);