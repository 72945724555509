import { UiPage } from "./reducer";

export enum UiActionType {
  TOGGLE_FLASH = 'ui/TOGGLE_FLASH',
  TOGGLE_FREEZE = 'ui/TOGGLE_FREEZE',
  TOGGLE_INVERT = 'ui/TOGGLE_INVERT',
  TOGGLE_SLIDER = 'ui/TOGGLE_SLIDER',
  SET_PAGE = 'ui/SET_PAGE',
}

export const UiActions = {
  toggleFlash: (flashOn: boolean) => ({
    type: UiActionType.TOGGLE_FLASH,
    flashOn,
  }),
  toggleFreeze: (freezeOn: boolean) => ({
    type: UiActionType.TOGGLE_FREEZE,
    freezeOn,
  }),
  toggleInvert: (invertOn: boolean) => ({
    type: UiActionType.TOGGLE_INVERT,
    invertOn,
  }),
  toggleSlider: (sliderOn: boolean) => ({
    type: UiActionType.TOGGLE_SLIDER,
    sliderOn,
  }),
  setPage: (page: UiPage) => ({
    type: UiActionType.SET_PAGE,
    page,
  }),
}