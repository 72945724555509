import * as React from 'react';
import './Landing.css';
import UAParser from 'ua-parser-js';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { IDispatch, RootState } from '../store/rootState';
import { UiActions } from '../store/ui/actions';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SettingsIcon from '@material-ui/icons/Settings';
import InstallMobileIcon from '@mui/icons-material/InstallMobile';
import InfoIcon from '@mui/icons-material/Info';
import { UiPage } from '../store/ui/reducer';
import { Header } from './Header';
import { Link } from 'react-router-dom';

interface StateProps {
}

export interface DispatchProps {
  uiActions: typeof UiActions;
}

type Props = StateProps & DispatchProps;

class LandingImpl extends React.Component<Props> {
  refInstall = React.createRef<HTMLDivElement>();
  deferredPrompt: any;

  componentDidMount() {
  }

  render() {
    const note = new UAParser().getDevice().type !== 'mobile' ? `Note: This site is optimized to be used on a smartphone or a tablet.` : ``;
    return (
      <div className="container">
        <Header />
        <div className="content">
          <div className="pageTitle">Home</div>
          Magnifier Online is a full featured web-based magnifying glass, completely free, without having to install any app!<br />
          Camera access permission is required in order to use this magnifier, please grant it when prompted.<br />
          {note}
        </div>
        <div className="mainButtons">
          <Link className="button" to="/magnifier">
            <PlayArrowIcon /> Start
          </Link>
          <Link className="button" to="/settings">
            <SettingsIcon /> Settings
          </Link>
          <div ref={this.refInstall} className="button" onClick={() => alert('Not supported yet')}>
            <InstallMobileIcon /> Install
          </div>
          <Link className="button" to="/about">
            <InfoIcon /> About
          </Link>
        </div>
      </div>
    );
  }
}

export const Landing: any = connect<StateProps, DispatchProps, never, never>(
  (state: RootState) => ({
  }),
  (dispatch: IDispatch) => ({
    uiActions: bindActionCreators(UiActions, dispatch)
  })
)(LandingImpl);