import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ReactDOM from 'react-dom';
import { Landing } from './components/Landing';
import './index.css';
import reportWebVitals from './reportWebVitals';
import { AnyAction, Store } from 'redux';
import { RootState } from './store/rootState';
import { createStore } from 'redux';
import { rootReducer } from './store/rootReducer';
import { Provider } from 'react-redux';
import { About } from './components/About';
import { Main } from './components/Main';
import { Settings } from './components/Settings';

let store: Store<RootState, AnyAction> | null = createStore(rootReducer as any) as Store<RootState>;

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/about" element={<About />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/magnifier" element={<Main />} />
      </Routes>
    </BrowserRouter>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
