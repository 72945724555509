import * as React from 'react';
import './Slider.css';
import { connect } from 'react-redux';
import { IDispatch, RootState } from '../store/rootState';

interface OwnProps {
  minValue: number;
  maxValue: number;
  step: number;
  onChange: (zoomValue: number) => {};
  refSlider: React.RefObject<HTMLInputElement>;
}

interface StateProps {
  isSliderOn: boolean;
}

export interface DispatchProps {
}

type Props = OwnProps & StateProps & DispatchProps;

class SliderImpl extends React.Component<Props> {
  render() {
    return (
      <input
        id="slider"
        type="range"
        className="zoomSlider"
        min={this.props.minValue}
        max={this.props.maxValue}
        step={this.props.step}
        defaultValue={7}
        ref={this.props.refSlider}
        onChange={(event) => this.props.onChange(Number(event.currentTarget.value)) }        
        style={{ display: this.props.isSliderOn ? 'flex' : 'none' }}
      />
    );
  }
}

export const Slider: any = connect<StateProps, DispatchProps, never, never>(
  (state: RootState) => ({
    isSliderOn: state.ui.isSliderOn,
  }),
  (dispatch: IDispatch) => ({
  })
)(SliderImpl);