import { useEffect  } from 'react';
import './Ad.css';

const Ad = (props: any) => {
  const { dataAdSlot } = props;  

  useEffect(() => {
    // @ts-ignore
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  },[]);

  return (
    <>
      <ins className="adsbygoogle example_responsive_1"
          style={{ display: "block", minWidth: "300px", maxWidth: "970px", width: "100%", height: "90px" }}
          data-ad-client="ca-pub-9356353140664407"
          data-ad-slot={dataAdSlot}>
      </ins>
    </>
  );
};

export default Ad;