import { handleActions } from 'redux-actions';
import { UiActionType } from './actions';

export enum UiPage  {
  Landing = 'Landing',
  Main = 'Main',
  Settings = 'Settings',
  About = 'About',
}

export interface IUiState {
  isFlashOn: boolean;
  isFreezeOn: boolean;
  isInvertOn: boolean;
  isSliderOn: boolean;
  page: UiPage;
}

export const initialState: IUiState = {
  isFlashOn: false,
  isFreezeOn: false,
  isInvertOn: false,
  isSliderOn: true,
  page: UiPage.Landing,
};

export const uiReducer = handleActions<IUiState>(
  {
    [UiActionType.TOGGLE_FLASH]: (state, action: any) => {
      return {
        ...state,
        isFlashOn: action.flashOn,
      };
    },
    [UiActionType.TOGGLE_FREEZE]: (state, action: any) => {
      return {
        ...state,
        isFreezeOn: action.freezeOn,
      };
    },
    [UiActionType.TOGGLE_INVERT]: (state, action: any) => {
      return {
        ...state,
        isInvertOn: action.invertOn,
      };
    },
    [UiActionType.TOGGLE_SLIDER]: (state, action: any) => {
      return {
        ...state,
        isSliderOn: action.sliderOn,
      };
    },
    [UiActionType.SET_PAGE]: (state, action: any) => {
      return {
        ...state,
        page: action.page,
      };
    },
  },
  initialState,
);